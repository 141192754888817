<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Active Members</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>

      Active members within your company!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center" v-if="!loading">
      {{ count }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :to="{ name: 'cprMyCompanyManagement' }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PrincipalCompanyWidget",
  data() {
    return {
      count: 0,
      loading: true
    };
  },
  mounted() {
    // this.fetchData();
  },
  methods: {
    fetchData() {
      let fullParticipantTypeKeys = [];
      let participantTypeKeys = [];
      fullParticipantTypeKeys["directReseller"] = ["500", "510", "520", "530"];
      fullParticipantTypeKeys["directDistributor"] = ["600", "610", "620"];
      fullParticipantTypeKeys["directFulfillment"] = ["700", "710", "720", "730"];
      fullParticipantTypeKeys["indirectReseller"] = ["800", "810", "820", "830"];

      //Direct Reseller - 500 (principal) & 510 (admin)
      if (["500", "510"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directReseller"];
      } //Direct Distributor - 600 (principal) & 610 (admin)
      else if (["600", "610"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directDistributor"];
      } //Direct Fulfillment Reseller - 700 (principal) & 710 (admin)
      else if (["700", "710"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directFulfillment"];
      } //Indirect (3rd Party) Reseller - 800 (principal) & 810 (admin)
      else if (["800", "810"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["indirectReseller"];
      }

      this.loading = true;
      this.$api
        .post("/api/participantTypes/search", {
          participantTypeKeys: participantTypeKeys
        })
        .then(({ data }) => {
          let participantTypes = data.content.map(participantType => {
            return { id: participantType.id };
          });
          return this.$api
            .post("/api/participants/search", {
              organization: { id: this.selectedParticipant.organization.id },
              statuses: [{ name: "ENR" }, { name: "PRE" }],
              participantTypes: participantTypes,
              effectiveFrom: this.selectedFiscalYear.fiscalYearStart,
              effectiveTo: this.selectedFiscalYear.fiscalYearEnd
            })
            .then(({ data }) => {
              this.count = data.totalElements;
              return Promise.resolve(this.count);
            });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  }
};
</script>
