var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedSurvey)?_c('v-alert',{staticClass:"mt-6 mb-6",attrs:{"border":"top","colored-border":"","color":"primary","elevation":"2","height":"auto"}},[_c('v-carousel',{attrs:{"hide-delimiter-background":true,"hide-delimiters":true,"show-arrows":!(this.page == 0 || this.page == this.surveyQuestions.length + 1 || !this.isCurrentQuestionAnswered),"height":"auto"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('v-carousel-item',{attrs:{"eager":""}},[_c('h3',{staticClass:"headline",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.selectedSurvey ? _vm.selectedSurvey.name : "")+" ")]),_c('p',{attrs:{"align":"center"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedSurvey ? _vm.selectedSurvey.description : '')}}),_c('br'),_c('br'),_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.takingSurvey = true;
            if (_vm.isPendingSurvey) {
              _vm.page = _vm.firstNonAnsweredQuestion;
            } else {
              _vm.page++;
            }}}},[_vm._v(_vm._s(this.isPendingSurvey ? "Continue Survey" : "Take Survey"))])],1)]),_vm._l((_vm.surveyQuestions),function(question,index){return _c('v-carousel-item',{key:index,attrs:{"eager":""}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.surveyQuestions && _vm.surveyQuestions.length > 0)?_c('h2',{staticClass:"headline",attrs:{"rules":[function (v) { return !!v || 'Required'; }],"align":"center"},domProps:{"textContent":_vm._s(question.description)}}):_vm._e()])],1),(question && question.surveyQuestionType && question.surveyQuestionType.name == 'CHECK_BOXES')?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},_vm._l((question.options),function(option,index){return _c('v-checkbox',{key:index,ref:'option' + index,refInFor:true,staticClass:"mt-n3",attrs:{"value":option,"label":option.description,"dense":""},on:{"change":function($event){_vm.dirtySurvey = true}},model:{value:(question.answers),callback:function ($$v) {_vm.$set(question, "answers", $$v)},expression:"question.answers"}})}),1)],1):_vm._e(),(question && question.surveyQuestionType && question.surveyQuestionType.name == 'MULTIPLE_CHOICE')?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio-group',{model:{value:(question.answers),callback:function ($$v) {_vm.$set(question, "answers", $$v)},expression:"question.answers"}},_vm._l((question.options),function(option,index){return _c('v-radio',{key:index,ref:'option' + index,refInFor:true,attrs:{"value":option,"label":option.description,"dense":""},on:{"change":function($event){_vm.dirtySurvey = true}}})}),1)],1)],1):_vm._e(),(
            _vm.surveyQuestions && question && question.surveyQuestionType && question.surveyQuestionType.name == 'TEXT'
          )?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"hint":question.textAnswer ? 'Characters left: ' + (300 - question.textAnswer.length) : '',"maxlength":"300"},on:{"change":function($event){_vm.dirtySurvey = true}},model:{value:(question.textAnswer),callback:function ($$v) {_vm.$set(question, "textAnswer", $$v)},expression:"question.textAnswer"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.isCurrentQuestionAnswered},on:{"click":function($event){_vm.page++}}},[_vm._v("Next")])],1)],1)],1)}),(_vm.takingSurvey)?_c('v-carousel-item',{on:{"change":function($event){return _vm.submitAnswers()}}},[_c('h3',{staticClass:"headline",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.selectedSurvey ? _vm.completedTitle : "")+" ")]),_c('h5',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.selectedSurvey ? _vm.completedDescription : "")+" ")]),_c('p',{attrs:{"align":"center"}},[_c('br'),_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading},on:{"click":function($event){if (_vm.participantHasPendingSurveys) {
              _vm.fetchNextSurvey().then(function () {
                _vm.page = 0;
              });
            } else {
              _vm.selectedSurvey = null;
            }}}},[_vm._v(_vm._s(_vm.participantHasPendingSurveys ? "Next Survey" : "Close"))])],1)]):_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }