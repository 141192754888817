<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">My Accounts</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      As an Account Manager, you are currently managing these companies!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center">
      <v-data-table :items="relatedParticipants" :headers="relatedParticipantHeaders" hide-default-footer>
        <template v-slot:item.organization.organizationKey="{ item }">
          <router-link :to="{ name: 'cprMyCompanyClaimsWithId', params: { id: item.organization.id } }">
            {{ item.organization.organizationKey }}</router-link
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :to="{ name: 'cprMyAccounts' }">More</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountManagerOrganizationsWidget",
  data() {
    return {
      relatedParticipants: [],
      relatedParticipantHeaders: [
        {
          value: "organization.organizationKey",
          text: "SAP Account #",
          sortable: true
        },
        {
          value: "organization.name",
          text: "Name",
          sortable: true
        },
        {
          value: "organization.dba",
          text: "DBA",
          sortable: true
        },
        {
          value: "organization.organizationType.name",
          text: "Type",
          sortable: true
        }
      ],
      loading: true
    };
  },
  mounted() {},
  methods: {
    fetchData() {
      this.loading = true;
      this.$api
        .post("/api/relatedParticipants/search", {
          relatedParticipant: { id: this.selectedParticipant.id },
          enrolledAfter: this.selectedFiscalYear.fiscalYearStart,
          enrolledBefore: this.selectedFiscalYear.fiscalYearEnd
        })
        .then(({ data }) => {
          this.relatedParticipants = data.content;
          let promises = [];
          this.relatedParticipants.forEach(elem => {
            promises.push(
              this.$api
                .post("/api/claims/search", {
                  organization: { id: elem.organization.id },
                  completelyPaid: true
                })
                .then(({ data }) => {
                  if (data.totalElements == 0 && !elem.organization.effective) {
                    this.relatedParticipants = this.relatedParticipants.filter(
                      relatedParticipant => relatedParticipant.id != elem.id
                    );
                  }
                })
            );
          });
          Promise.all(promises).then(() => {
            this.loading = false;
          });
        })
        .finally(() => {});
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  }
};
</script>
