<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Total Earnings</v-card-title>
    <v-card-subtitle> Total amount earned in this program!</v-card-subtitle>
    <v-card-text class="display-2 text-center">
      {{ selectedParticipant.bank.calculatedEarned | toNumber(2, selectedProgram) }}
      {{ selectedParticipant.bank.calculatedEarnedCurrency ? selectedParticipant.bank.calculatedEarnedCurrency : "" }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantEarnedWidget",
  data() {
    return {
      participant: {},
      result: null
    };
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
