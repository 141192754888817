<template>
  <v-card>
    <v-card-title>
      {{ $i18n.translate("Product Registration: RSA Contest Leaderboard") }}
      <v-spacer />
      <v-btn-toggle v-model="region" class="mr-2" color="primary" v-if="isAdmin">
        <v-btn small value="West">
          {{ $i18n.translate("West Region") }}
        </v-btn>
        <v-btn small value="East">
          {{ $i18n.translate("East Region") }}
        </v-btn>
      </v-btn-toggle>
      <div class="d-flex justify-end" v-else-if="!loading">
        <v-chip v-if="showCurrentRankChip" color="primary"> Current rank: {{ getCurrentRank }} </v-chip>
        <v-chip v-else-if="!hasRank" color="primary" class="d-flex justify-end">
          {{ $i18n.translate("You are not currently participating in this contest") }}
        </v-chip>
      </div>
    </v-card-title>
    <v-card-subtitle class="subtitle">
      <span>
        ({{ startDate | formatDateClient("MM/DD/YY", selectedClient) }} -
        {{ endDate | formatDateClient("MM/DD/YY", selectedClient) }}) | Last Updated on:
        {{ lastRunDate | formatDateClient("MM/DD/YY", selectedClient) }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <v-tabs v-model="tab" @change="onTabChange">
        <v-tab v-for="(contest, index) in contestHistory" :key="index">
          {{ tabName(contest) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(contest, index) in contestHistory" :key="index">
          <v-card flat>
            <v-card-text>
              <v-data-table
                class="elevation-1"
                :headers="computedHeaders"
                :items="contest.entries"
                :items-per-page="5"
                :loading="loading"
                :options="options"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr :style="getRowStyle(item)">
                    <td>
                      {{ item.currentRank }}
                    </td>
                    <td>
                      {{ redactFirstNames(item) }}
                    </td>
                    <td>
                      {{ item.organizationName }}
                    </td>
                    <td>
                      {{ item.entryValuesSum }}
                    </td>
                    <td v-if="contestHasAWinner">
                      <v-chip v-if="isWinner(item)" class="ml-2" color="green" dark>
                        {{ $i18n.translate("Winner") }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="showRedeemButton(item)"
                        color="primary"
                        small
                        class="mr-2"
                        @click="navigateToSelectAward(selectedContest)"
                      >
                        {{ $i18n.translate("Redeem") }}
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import UtilService from "@/gapp-components/services/util.service.js";

export default {
  name: "ContestEntryTopWidget",
  props: {
    activePromotion: {
      type: Object,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      tab: 0,
      region: null,
      headers: [
        {
          value: "currentRank",
          text: "Rank"
        },
        {
          value: "participantFirstName",
          text: "First Name"
        },
        {
          value: "organizationName",
          text: "Organization Name"
        },
        {
          value: "entryValuesSum",
          text: "Entries"
        },
        {
          value: "participantSelectedDateCount",
          text: "Status"
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false
        }
      ],
      options: {
        sortBy: ["entryValuesSum"],
        sortDesc: [true]
      },
      selectedContest: null,
      contestHistory: [],
      items: {
        current: [],
        previous: []
      },
      loading: false,
      lastRunDate: null
    };
  },
  mounted() {
    if (!this.isAdmin) {
      this.region = this.regionForNonAdmin;
    }
    this.getData();
    this.getLastRunDate();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"]),
    isEastRegion() {
      return this.selectedParticipant.organization.customFieldValue2 === "East";
    },
    isWestRegion() {
      return this.selectedParticipant.organization.customFieldValue2 === "West";
    },
    regionForNonAdmin() {
      return this.isEastRegion ? "East" : this.isWestRegion ? "West" : null;
    },
    startDate() {
      return this.selectedContest && this.selectedContest.effectiveDate ? this.selectedContest.effectiveDate : "";
    },
    endDate() {
      return this.selectedContest && this.selectedContest.expirationDate ? this.selectedContest.expirationDate : "";
    },
    getCurrentRank() {
      const entry = this.selectedContest?.entries.find(entry => entry.participantId === this.selectedParticipant.id);
      return entry?.currentRank || null;
    },
    computedHeaders() {
      if (!this.contestHasAWinner) {
        this.sortBy("entryValuesSum");
        return this.headers.filter(header => header.value !== "participantSelectedDateCount");
      }
      this.sortBy("participantSelectedDateCount");
      return this.headers;
    },
    contestHasAWinner() {
      return this.selectedContest?.entries?.some(entry => entry.participantSelectedDateCount > 0);
    },
    showCurrentRankChip() {
      return this.isCurrentMonth && this.hasRank;
    },
    isCurrentMonth() {
      const selectedMonth = moment(this.selectedContest?.effectiveDate).month();
      const currentMonth = moment().month();
      return selectedMonth === currentMonth;
    },
    hasRank() {
      return this.getCurrentRank > 0;
    }
  },
  watch: {
    region: {
      handler() {
        this.getData();
      }
    }
  },
  methods: {
    redactFirstNames(item) {
      if (this.selectedParticipant.participantType.participantTypeKey === "100") {
        return item.participantFirstName;
      }
      if (
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationKey === item.organizationKey
      ) {
        return item.participantFirstName;
      }
      return item.participantFirstName.replace(/./g, "*");
    },
    onTabChange(index) {
      this.tab = index;
      this.selectedContest = this.contestHistory[index];
    },
    isWinner(item) {
      return item.participantSelectedDateCount > 0;
    },
    sortBy(column) {
      this.options.sortBy = [column];
    },
    getData() {
      this.loading = true;
      let promotionFilters = {};
      promotionFilters.promotionType = { promotionTypeKey: "RSA Incentive" };
      promotionFilters.fiscalYear = this.selectedFiscalYear.fiscalYear;
      promotionFilters.isEffective = "All";

      this.$api.post("/api/promotions/search/simple", promotionFilters).then(({ data }) => {
        let contests = data.content
          .filter(item => item.contest && item.contest.id)
          .map(item => {
            let contest = item.contest;
            contest.promotionId = item.id;
            return contest;
          })
          .sort((a, b) => {
            if (a.effective && !b.effective) return -1;
            if (!a.effective && b.effective) return 1;
            return moment(b.effectiveDate).diff(moment(a.effectiveDate));
          });

        let promises = contests.map(contest => {
          let filters = {
            contest: { id: contest.id },
            organizationCustomFieldValue2: this.region
          };
          return this.$api.post("/api/contestEntries/search/grouped/byParticipant", filters).then(({ data }) => {
            contest.entries = data.content;
            if (contest.entries.length > 0) {
              contest.entries.sort((a, b) => a.currentRank - b.currentRank);
            }
            return contest;
          });
        });

        Promise.all(promises).then(results => {
          this.contestHistory = results;
          this.selectedContest = this.contestHistory[this.tab];
          this.loading = false;
        });
      });
    },
    async getLastRunDate() {
      this.loading = true;
      this.lastRunDate = await this.$sonyProgram.getJobLastRunDateByJobKey("sony-rsa-incentive");
      this.loading = false;
    },
    getRowStyle(item) {
      let colors = this.$chart.getColors();
      if (item.participantId === this.selectedParticipant.id) {
        return {
          backgroundColor: colors[0],
          color: "white"
        };
      }
      if (item.participantSelectedDateCount > 0) {
        return {
          backgroundColor: colors[1],
          color: "white"
        };
      }
      return {};
    },
    tabName(contest) {
      return UtilService.getDateInClientTimezone(contest.effectiveDate).format("MMMM");
    },
    navigateToSelectAward(contest) {
      this.$router.push({ name: "SelectAward", params: { id: contest.promotionId } });
    },
    showRedeemButton(item) {
      return (
        this.contestHasAWinner &&
        this.selectedParticipant.id === item.participantId &&
        this.isWinner(item) &&
        item.participantSelectedPrizeCount === 0
      );
    }
  }
};
</script>
