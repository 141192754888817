<template>
  <v-container>
    <v-alert border="top" colored-border color="primary" elevation="2">
      <p v-if="selectedProgram.programInformation" v-html="selectedProgram.programInformation"></p>
      <h3 v-else class="headline">Welcome to Sony Rebate Center!</h3>
    </v-alert>
    <Survey />
    <template>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <ParticipantEarnedWidget height="100%"></ParticipantEarnedWidget>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6" v-if="selectedParticipant.preferredAwardVehicle">
          <v-badge color="primary" content="Preferred Payout" overlap offset-x="120" style="width: 100%">
            <AwardVehicleCard
              class="preferred-award-vehicle-card"
              selected
              :awardVehicle="selectedParticipant.preferredAwardVehicle"
              height="100%"
              :readOnly="true"
            />
          </v-badge>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AwardVehicleCard from "../../gapp-components/components/common/awardVehicle/AwardVehicleCard.vue";
import Survey from "../../gapp-components/components/surveys/Survey.vue";
import ParticipantEarnedWidget from "./widget/ParticipantEarnedWidget.vue";
import { mapGetters } from "vuex";

export default {
  name: "EveDashboard",
  metaInfo: {
    title: "Dashboard E-Vehicle"
  },
  components: { ParticipantEarnedWidget, Survey, AwardVehicleCard },
  data: () => ({
    env: null
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.breadcrumb();
    },
    breadcrumb() {
      this.$store.dispatch("setBreadcrumb", []);
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"])
  }
};
</script>
