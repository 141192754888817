var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._g(_vm._b({attrs:{"loading":_vm.loading}},'v-card',_vm.$attrs,false),_vm.$listeners),[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.total)+" Open Claims")]),_c('v-card-subtitle',[(
        this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
      )?_c('span',[_c('b',[_vm._v("("+_vm._s(_vm._f("formatDateClient")(this.selectedFiscalYear.fiscalYearStart,"MM/DD/YYYY", _vm.selectedClient))+" - "+_vm._s(_vm._f("formatDateClient")(this.selectedFiscalYear.fiscalYearEnd,"MM/DD/YYYY", _vm.selectedClient))+")")]),_c('br')]):_vm._e(),_vm._v(" Open Claims in the program!")]),(!_vm.loading)?_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":6,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.createdDate))+" ")]}},{key:"item.claimStage.name",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatStage")(item.claimStage,item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.claimStage.description))])])]}}],null,false,2429734861)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }