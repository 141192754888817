<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Active Promotion</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br /></span
      >Active Promotion in the program!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center" v-if="!loading">
      {{ count | toNumber }}
    </v-card-text>
    <v-card-actions v-if="showActions">
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="{ name: 'salesnewsLibrary', query: { active: 1 } }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminPromotionsWidget",
  props: {
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      count: 0,
      loading: true
    };
  },
  mounted() {
    // no need for this because it is driven by the fiscal year 'watcher'
    // this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$api
        .post("/api/promotions/search?size=1", {
          isSubmissionsEffective: true,
          fiscalYear: this.selectedFiscalYear.fiscalYear
        })
        .then(({ data }) => {
          this.count = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    },
    selectedProgram() {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
