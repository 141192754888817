<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Active Claims</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br /></span
      >Submitted Claims that have not been closed out!</v-card-subtitle
    >
    <v-card-text v-if="!loading">
      <v-data-table hide-default-footer :headers="headers" :items="items">
        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th class="text-right">{{ sumField("count") }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="showActions">
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="{ name: 'cprClaimAuditManagement' }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminClaimsWidget",
  props: {
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headers: [
        {
          value: "promotionTypeName",
          text: "Promotion Type",
          sortable: false
        },
        {
          value: "count",
          text: "Count",
          align: "right",
          sortable: false
        }
      ],
      items: [],
      loading: false
    };
  },
  methods: {
    sumField(key) {
      return this.items.reduce((a, b) => a + (b[key] || 0), 0);
    },
    fetchData() {
      this.loading = true;
      let filters = {
        submitted: true,
        closed: false,
        fiscalYear: this.selectedFiscalYear.fiscalYear
      };
      this.$api
        .post("/api/claims/search/byPromotionType?size=100", filters)
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    // no need for this because it is driven by the fiscal year 'watcher'
    // this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  }
};
</script>
