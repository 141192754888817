<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-toolbar flat>
      <v-toolbar-title class="headline"
        >Submitted Claims by Company
        <span> (Last {{ selectedDays }} Days)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" small @click="getData(7)" :loading="loading" :disabled="selectedDays == 7">
        Last 7 days
      </v-btn>
      <v-btn class="mr-2" small @click="getData(30)" :loading="loading" :disabled="selectedDays == 30">
        Last 30 days
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <apexchart type="bar" height="400" :options="options" :series="series"></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "ClaimsSubmittedByOrganizationBarChart",
  components: {},
  data: () => ({
    loading: false,
    selectedDays: 30,

    options: {
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      xaxis: {
        type: "category"
      }
    },
    series: []
  }),
  methods: {
    getData(days) {
      this.loading = true;

      if (!days) {
        days = 30;
      }
      this.selectedDays = days;

      let submittedAfter = moment()
        .subtract(days, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      this.$api
        .post("/api/claims/search/byOrganization", {
          submitted: true,
          submittedAfter: submittedAfter
        })
        .then(({ data }) => {
          let s = data.map(d => {
            return {
              x: d.organizationName,
              y: d.count
            };
          });
          this.series = [{ data: s }];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.options.colors = this.$chart.getColors();

    this.getData();
  }
};
</script>
