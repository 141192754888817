<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">My Account Managers</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      As an Regional Manager, you are currently managing these account managers!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center">
      <v-data-table :items="participants" :headers="participantHeaders" hide-default-footer>
        <template v-slot:item.status="{ item }">
          {{ item.status.description }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :to="{ name: 'cprMyAccountManagers' }">More</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountManagersWidget",
  data() {
    return {
      participants: [],
      participantHeaders: [
        {
          value: "participantKey",
          text: "SAP #",
          sortable: true
        },
        {
          value: "user.firstName",
          text: "First Name",
          sortable: true
        },
        {
          value: "user.lastName",
          text: "Last Name",
          sortable: true
        },
        {
          value: "status",
          text: "Status",
          sortable: true
        },
        {
          value: "email1",
          text: "Email",
          sortable: true
        }
      ],
      loading: true
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$api
        .post("/api/participants/search", {
          reportToParticipant: { id: this.selectedParticipant.id },
          effectiveFrom: this.selectedFiscalYear.fiscalYearStart,
          effectiveTo: this.selectedFiscalYear.fiscalYearEnd
        })
        .then(({ data }) => {
          this.participants = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    // this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  }
};
</script>
