<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">
        <v-btn
          v-if="
            $privilege.hasPrivilege('CLAIM_CREATE') &&
              hasAccess({
                participantTypes: [
                  '100',
                  '500',
                  '510',
                  '520',
                  '530',
                  '600',
                  '610',
                  '620',
                  '700',
                  '710',
                  '720',
                  '730',
                  '800',
                  '810',
                  '820',
                  '830',
                  '900',
                  '910',
                  '930',
                  '1030'
                ],
                programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
              })
          "
          color="success"
          :to="{ name: 'cprClaims' }"
          >Start a new Claim</v-btn
        >
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div style="width: 115px; display: inline-block" v-bind="attrs" v-on="on">
              <FiscalYearField
                dense
                hide-details
                label="Fiscal Year"
                hint="Fiscal Year"
                persistent-hint
                solo-inverted
                dark
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                :client="selectedClient"
                @inputObj="selectedFiscalYear = $event"
                use-store
              >
              </FiscalYearField>
            </div>
          </template>
          Fiscal Year
        </v-tooltip>
      </v-col>
    </v-row>
    <v-alert border="top" colored-border color="primary" elevation="2">
      <p v-if="selectedProgram.programInformation" v-html="selectedProgram.programInformation"></p>
      <h3 v-else class="headline">Welcome to Sony Rebate Center!</h3>
    </v-alert>
    <Survey />
    <template
      v-if="
        hasAccess({
          participantTypes: ['100', '200'],
          programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
        })
      "
    >
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="4">
              <AdminParticipantsWidget
                :showActions="
                  hasAccess({
                    participantTypes: ['100'],
                    programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
                  })
                "
              ></AdminParticipantsWidget>
            </v-col>
            <v-col cols="4">
              <AdminCompaniesWidget
                :showActions="
                  hasAccess({
                    participantTypes: ['100'],
                    programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
                  })
                "
              ></AdminCompaniesWidget>
            </v-col>
            <v-col cols="4">
              <AdminPromotionsWidget
                :showActions="
                  hasAccess({
                    participantTypes: ['100'],
                    programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
                  })
                "
              ></AdminPromotionsWidget>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimTopPromotionWidget showBusinessUnitFilter></ClaimTopPromotionWidget>
            </v-col>
          </v-row>

          <v-row v-if="activeRsaPromotion">
            <v-col cols="12">
              <ContestEntryTopWidget
                :active-promotion="activeRsaPromotion"
                :is-admin="
                  hasAccess({
                    participantTypes: ['100'],
                    programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
                  })
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedForWeekBarChart></ClaimsSubmittedForWeekBarChart>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedByOrganizationBarChart></ClaimsSubmittedByOrganizationBarChart>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <AdminClaimsWidget
                :showActions="
                  hasAccess({
                    participantTypes: ['100'],
                    programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
                  })
                "
              ></AdminClaimsWidget>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="
        hasAccess({
          participantTypes: ['300', '400'],
          programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
        })
      "
    >
      <v-row>
        <v-col cols="12">
          <ClaimTopPromotionWidget showBusinessUnitFilter></ClaimTopPromotionWidget>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        hasAccess({ participantTypes: ['100'], programs: [env.VUE_APP_PROGRAM_EUR_KEY, env.VUE_APP_PROGRAM_CEUR_KEY] })
      "
    >
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="6">
              <AdminParticipantsWidget></AdminParticipantsWidget>
            </v-col>
            <v-col cols="6">
              <AdminPromotionsWidget></AdminPromotionsWidget>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedForWeekBarChart></ClaimsSubmittedForWeekBarChart>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedByOrganizationBarChart></ClaimsSubmittedByOrganizationBarChart>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <AdminClaimsWidget></AdminClaimsWidget>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        !loading && hasAccess({ participantTypes: ['520', '530', '620', '720', '730', '820', '830', '930', '1030'] })
      "
    >
      <v-row v-if="!showParticipantEntryEarnedWidget && !showPreferredAwardVehicleCard">
        <v-col cols="6">
          <ParticipantEarnedWidget :to="{ name: 'cprMyClaims', query: { tab: 0 } }" height="100%" />
        </v-col>
        <v-col cols="6">
          <ParticipantPendingPaymentWidget :to="{ name: 'cprMyClaims', query: { tab: 1 } }" height="100%"
        /></v-col>
      </v-row>

      <v-row
        v-if="
          (showParticipantEntryEarnedWidget && !showPreferredAwardVehicleCard) ||
            (!showParticipantEntryEarnedWidget && showPreferredAwardVehicleCard)
        "
      >
        <v-col cols="4">
          <ParticipantEarnedWidget
            :to="{ name: 'cprMyClaims', query: { tab: 0 } }"
            height="100%"
          ></ParticipantEarnedWidget>
        </v-col>

        <v-col cols="4">
          <ParticipantPendingPaymentWidget
            :to="{ name: 'cprMyClaims', query: { tab: 1 } }"
            height="100%"
          ></ParticipantPendingPaymentWidget>
        </v-col>

        <v-col cols="4">
          <ParticipantEntryEarnedWidget
            v-if="showParticipantEntryEarnedWidget"
            :active-promotion="activeRsaPromotion"
            height="100%"
          />

          <v-badge
            v-if="showPreferredAwardVehicleCard"
            color="primary"
            content="Preferred Payout"
            overlap
            offset-x="120"
            style="width: 100%"
          >
            <AwardVehicleCard
              class="preferred-award-vehicle-card"
              selected
              :awardVehicle="selectedParticipant.preferredAwardVehicle"
              height="100%"
              :readOnly="true"
              lookupPrimaryBankAccount
            />
          </v-badge>
        </v-col>
      </v-row>

      <v-row v-if="showParticipantEntryEarnedWidget && showPreferredAwardVehicleCard">
        <v-col cols="6">
          <ParticipantEarnedWidget :to="{ name: 'cprMyClaims', query: { tab: 0 } }" height="100%" />
        </v-col>
        <v-col cols="6">
          <ParticipantPendingPaymentWidget :to="{ name: 'cprMyClaims', query: { tab: 1 } }" height="100%" />
        </v-col>
      </v-row>
      <v-row v-if="showParticipantEntryEarnedWidget && showPreferredAwardVehicleCard">
        <v-col cols="6">
          <ParticipantEntryEarnedWidget :active-promotion="activeRsaPromotion" height="100%" />
        </v-col>
        <v-col cols="6">
          <v-badge color="primary" content="Preferred Payout" overlap offset-x="120" style="width: 100%">
            <AwardVehicleCard
              class="preferred-award-vehicle-card"
              selected
              :awardVehicle="selectedParticipant.preferredAwardVehicle"
              height="100%"
              :readOnly="true"
              lookupPrimaryBankAccount
            />
          </v-badge>
        </v-col>
      </v-row>
    </template>

    <template v-if="isParticipantEligibleForRSA && activeRsaPromotion">
      <v-row>
        <v-col cols="12">
          <ContestEntryTopWidget :active-promotion="activeRsaPromotion" />
        </v-col>
      </v-row>
    </template>
    <template v-if="hasAccess({ participantTypes: ['300'] })">
      <v-row>
        <v-col cols="12">
          <AccountManagersWidget height="100%"></AccountManagersWidget>
        </v-col>
      </v-row>
    </template>

    <template>
      <v-row>
        <v-col
          :cols="hasAccess({ isApprovingAccountManager: true }) || selectedParticipant.reportToParticipant ? 4 : 0"
          v-if="hasAccess({ participantTypes: ['400'] })"
        >
          <ReportToWidget class="mb-6"></ReportToWidget>
          <AccountManagerRegistrationWidget
            v-if="hasAccess({ isApprovingAccountManager: true })"
          ></AccountManagerRegistrationWidget>
        </v-col>
        <v-col
          :cols="hasAccess({ isApprovingAccountManager: false }) && !selectedParticipant.reportToParticipant ? 12 : 8"
          v-if="hasAccess({ participantTypes: ['400'] })"
        >
          <AccountManagerOrganizationsWidget height="100%"></AccountManagerOrganizationsWidget>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasAccess({ participantTypes: ['500', '510', '700', '800', '810', '900', '910'] })">
      <v-row>
        <v-col cols="4">
          <PrincipalCompanyWidget></PrincipalCompanyWidget>
        </v-col>
        <v-col cols="8"></v-col>
      </v-row>
    </template>
    <template v-if="hasAccess({ participantTypes: ['520', '530', '620', '720', '730', '820', '830', '930', '1030'] })">
      <v-row>
        <v-col cols="6">
          <CprClaims :widget="true"></CprClaims>
        </v-col>
        <v-col cols="6">
          <ParticipantClaimsWidget></ParticipantClaimsWidget>
        </v-col>
      </v-row>
    </template>
    <v-dialog :value="missingPreferredAwardVehicle && showPreferredAwardVehicleDialog" max-width="800" persistent>
      <v-card>
        <v-card-title>Good News!</v-card-title>
        <v-card-text>
          <p>
            There are multiple ways in which you can be paid out!
          </p>
          <p>
            Please update your preferred payout method within your
            <router-link :to="{ name: 'profile' }">profile</router-link> page.
          </p>
        </v-card-text>
        <v-card-actions>
          <span>
            <v-btn color="primary" :to="{ name: 'profile' }">Profile</v-btn>
          </span>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="showPreferredAwardVehicleDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import ClaimsSubmittedByOrganizationBarChart from "../../gapp-components/components/charts/ClaimsSubmittedByOrganizationBarChart.vue";
import ClaimsSubmittedForWeekBarChart from "../../gapp-components/components/charts/ClaimsSubmittedForWeekBarChart.vue";
import AwardVehicleCard from "../../gapp-components/components/common/awardVehicle/AwardVehicleCard.vue";
import ParticipantEntryEarnedWidget from "@/views/cpr/widget/ParticipantEntryEarnedWidget.vue";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import Survey from "../../gapp-components/components/surveys/Survey.vue";
import CprClaims from "./CprClaims.vue";
import AccountManagerOrganizationsWidget from "./widget/AccountManagerOrganizationsWidget.vue";
import AccountManagerRegistrationWidget from "./widget/AccountManagerRegistrationWidget.vue";
import AccountManagersWidget from "./widget/AccountManagersWidget.vue";
import AdminClaimsWidget from "./widget/AdminClaimsWidget.vue";
import AdminCompaniesWidget from "./widget/AdminCompaniesWidget.vue";
import AdminParticipantsWidget from "./widget/AdminParticipantsWidget.vue";
import AdminPromotionsWidget from "./widget/AdminPromotionsWidget.vue";
import ClaimTopPromotionWidget from "./widget/ClaimTopPromotionWidget.vue";
import ParticipantClaimsWidget from "./widget/ParticipantClaimsWidget.vue";
import ParticipantEarnedWidget from "./widget/ParticipantEarnedWidget.vue";
import ParticipantPendingPaymentWidget from "./widget/ParticipantPendingPaymentWidget.vue";
import PrincipalCompanyWidget from "./widget/PrincipalCompanyWidget.vue";
import ReportToWidget from "./widget/ReportToWidget.vue";
import ContestEntryTopWidget from "@/views/ccpr/widget/ContestEntryTopWidget.vue";

export default {
  name: "CprDashboard",
  metaInfo: {
    title: "Dashboard"
  },
  components: {
    FiscalYearField,
    AdminParticipantsWidget,
    AdminCompaniesWidget,
    AdminPromotionsWidget,
    ClaimTopPromotionWidget,
    ClaimsSubmittedForWeekBarChart,
    ClaimsSubmittedByOrganizationBarChart,
    AdminClaimsWidget,
    ParticipantEarnedWidget,
    ParticipantPendingPaymentWidget,
    AwardVehicleCard,
    ParticipantEntryEarnedWidget,
    AccountManagersWidget,
    ReportToWidget,
    AccountManagerRegistrationWidget,
    AccountManagerOrganizationsWidget,
    PrincipalCompanyWidget,
    CprClaims,
    ParticipantClaimsWidget,
    Survey,
    ContestEntryTopWidget
  },
  data: () => ({
    isParticipantEligibleForRSA: false,
    env: null,
    fiscalYearStart: null,
    fiscalYearEnd: null,
    availableAwardVehicles: [],
    showPreferredAwardVehicleDialog: true,
    selectedFiscalYear: {},
    showAccountManagerWidget: false,
    activeRsaPromotion: null,
    loading: false
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.initialize();
  },

  watch: {
    selectedParticipant() {
      this.initialize();
    }
  },

  methods: {
    initialize() {
      this.$store.dispatch("setBreadcrumb", []);
      let year = new moment().year();
      let fiscalYearStartMoment = moment(
        year + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
        "YYYY-MM-DD"
      );
      if (fiscalYearStartMoment.isAfter(new Date())) {
        fiscalYearStartMoment = moment(
          year - 1 + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
          "YYYY-MM-DD"
        );
      }

      this.fiscalYearStart = fiscalYearStartMoment.format("YYYY-MM-DDT00:00:00.000Z");
      this.fiscalYearEnd = fiscalYearStartMoment
        .add(1, "years")
        .add(-1, "days")
        .format("YYYY-MM-DDT00:00:00.000Z");

      this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant).then(avs => {
        this.availableAwardVehicles = avs;
      });

      this.$api
        .post("/api/relatedParticipants/search", {
          relatedParticipant: { id: this.selectedParticipant.id }
        })
        .then(({ data }) => {
          if (data.content && data.content.length > 0) {
            let anyOrganizationIsZd = data.content.some(relatedParticipant => {
              if (relatedParticipant.organization.organizationType.organizationTypeKey == "ZD") {
                return true;
              }
            });
            if (anyOrganizationIsZd && this.selectedParticipant.participantType.participantTypeKey == "400") {
              this.showAccountManagerWidget = true;
            }
          }
        });

      this.verifyIfParticipantIsEligibleForRSA();
    },
    hasAccess(obj) {
      if (obj.participantTypes && obj.participantTypes.length > 0) {
        if (!obj.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)) {
          return false;
        }
      }

      if (obj.organizationTypeKeys && obj.organizationTypeKeys.length > 0) {
        if (
          !obj.organizationTypeKeys.includes(this.selectedParticipant.organization.organizationType.organizationTypeKey)
        ) {
          return false;
        }
      }

      if (obj.programs && obj.programs.length > 0) {
        if (!obj.programs.includes(this.selectedProgram.programKey)) {
          return false;
        }
      }

      if (obj.isApprovingAccountManager != null) {
        return obj.isApprovingAccountManager == this.showAccountManagerWidget;
      }
      return true;
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    },

    verifyIfParticipantIsEligibleForRSA() {
      this.loading = true;
      this.$sonyProgram
        .findActiveRSAPromotion()
        .then(data => {
          this.activeRsaPromotion = data;
          this.isParticipantEligibleForRSA = this.isRsaParticipantType;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"]),

    missingPreferredAwardVehicle() {
      return this.$sonyProgram.participantNeedsPreferredAwardVehicle() && this.availableAwardVehicles.length > 1;
    },

    isRsaParticipantType() {
      return ["530", "620", "730", "830", "930"].includes(this.selectedParticipant.participantType.participantTypeKey);
    },

    showParticipantEntryEarnedWidget() {
      return this.activeRsaPromotion && this.isParticipantEligibleForRSA;
    },

    showPreferredAwardVehicleCard() {
      return this.selectedParticipant.preferredAwardVehicle ? true : false;
    }
  }
};
</script>
