<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Total Entries</v-card-title>
    <v-card-subtitle>Total entries earned in this month's contest!</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="4" class="d-flex justify-center align-center">
          <span class="display-2 text-center">{{ totalEntry }}</span> </v-col
        ><v-col cols="8">
          <span> <b>Start Date: </b> {{ startDate | formatDateClient("MM/DD/YYYY", selectedClient) }} </span>
          <br />
          <span> <b>End Date: </b> {{ endDate | formatDateClient("MM/DD/YYYY", selectedClient) }} </span>
          <br />
          <span> <b>Last Updated on: </b> {{ lastRunDate | formatDateClient("MM/DD/YYYY", selectedClient) }} </span>
          <br />
          <b>Click <a @click="onShowHistory">here</a> to history</b>
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="headline">Entries History</v-card-title>
              <v-card-text>
                <v-data-table :headers="headers" :items="historyItems" class="elevation-1">
                  <template v-slot:item.month="{ item }">
                    {{ monthName(item) }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "ParticipantEntryEarnedWidget",
  props: {
    activePromotion: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      totalEntry: undefined,
      currentPromotion: undefined,
      lastRunDate: null,
      dialog: false,
      historyItems: [],
      headers: [
        { text: "Month", value: "month" },
        { text: "Count", value: "count" }
      ]
    };
  },

  mounted() {
    this.getData();
    this.getLastRunDate();
  },

  methods: {
    getData() {
      this.loading = true;

      if (this.activePromotion && this.activePromotion.contest) {
        let contestEntryFilters = {};
        contestEntryFilters.contest = { id: this.activePromotion.contest.id };
        contestEntryFilters.participant = { id: this.selectedParticipant.id };

        return this.$api
          .post("/api/contestEntries/search", contestEntryFilters)
          .then(({ data }) => {
            this.totalEntry = data.totalElements;
          })
          .finally(() => {
            this.loading = false;
          });
      }

      console.log("Promotion does not have any Contest");
      this.loading = false;

      return;
    },
    async getLastRunDate() {
      this.loading = true;
      this.lastRunDate = await this.$sonyProgram.getJobLastRunDateByJobKey("sony-rsa-incentive");
      this.loading = false;
    },
    async onShowHistory() {
      try {
        this.loading = true;

        let promotionFilters = {};
        promotionFilters.promotionType = { promotionTypeKey: "RSA Incentive" };
        promotionFilters.fiscalYear = this.selectedFiscalYear.fiscalYear;
        promotionFilters.isEffective = "All";

        let { data: promotionResponse } = await this.$api.post(
          "/api/promotions/search/simple?sort=effectiveDate,desc",
          promotionFilters
        );
        let contests = promotionResponse.content.filter(item => item.contest).map(item => item.contest);

        this.historyItems = await Promise.all(
          contests.map(async contest => {
            let filters = {
              contest: { id: contest.id },
              participant: { id: this.selectedParticipant.id }
            };

            let { data } = await this.$api.post("/api/contestEntries/search/grouped/byParticipant", filters);
            contest.count = data && data.content && data.content.length > 0 ? data.content[0].entryValuesSum : null;

            return contest;
          })
        );

        this.loading = false;
        this.dialog = true;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    monthName(contest) {
      return moment(contest.effectiveDate).format("MMMM");
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedFiscalYear", "selectedClient"]),
    startDate() {
      return this.activePromotion && this.activePromotion.contest ? this.activePromotion.contest.effectiveDate : "";
    },
    endDate() {
      return this.activePromotion && this.activePromotion.contest ? this.activePromotion.contest.expirationDate : "";
    }
  }
};
</script>
