<template>
  <v-card v-bind="$attrs" v-on="$listeners" :loading="loading">
    <v-card-title class="headline">Earned</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      Total amount earned in this program!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center" v-if="result">
      {{ result.calculatedAmount | toNumber(2, selectedProgram) }}
      {{ result.calculatedAmountCurrency ? result.calculatedAmountCurrency : "" }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantEarnedWidget",
  data() {
    return {
      loading: false,
      participant: {},
      result: null
    };
  },
  methods: {
    getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = {
        submitted: true,
        closed: true,
        approved: true,
        participant: { id: this.selectedParticipant.id },
        fiscalYear: this.selectedFiscalYear.fiscalYear
      };

      this.$api
        .post("/api/claims/search/sumCalculatedTotalEarnedAmount", filters)
        .then(({ data }) => {
          this.result = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    selectedFiscalYear() {
      this.getData();
    },
    keyword() {
      this.getData();
    }
  },
  mounted() {
    if (!this.result) {
      this.getData();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
