<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Active Companies</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br /></span
      >Active Companies in the program!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center" v-if="!loading">
      {{ count | toNumber }}
    </v-card-text>
    <v-card-actions v-if="showActions">
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="{ name: 'cprCompanyManagement' }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AdminCompaniesWidget",
  props: {
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      count: 0,
      loading: true
    };
  },
  mounted() {
    // no need for this because it is driven by the fiscal year 'watcher'
    // this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    },
    selectedProgram() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.loading = true;

      let isCurrentFiscalYear = this.selectedFiscalYear.fiscalYear == moment().year();
      let filters = {
        isEffective: "Active"
      };
      if (!isCurrentFiscalYear) {
        filters.effectiveFrom = this.selectedFiscalYear.fiscalYearStart;
        filters.effectiveTo = this.selectedFiscalYear.fiscalYearEnd;
      }

      this.$api
        .post("/api/organizations/search?size=1", filters)
        .then(({ data }) => {
          this.count = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
