var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$i18n.translate("Promotions")))]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$i18n.translate("Customer"))+" ")]),_c('v-tab',[_vm._v(_vm._s(_vm.$i18n.translate("Participant")))])],1)]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(" Top Performing "),(_vm.periodStart && _vm.periodEnd)?_c('span',[_vm._v(" ("+_vm._s(_vm._f("formatDateClient")(_vm.periodStart,"MM/DD/YYYY", _vm.selectedClient))+" - "+_vm._s(_vm._f("formatDateClient")(_vm.periodEnd,"MM/DD/YYYY", _vm.selectedClient))+") ")]):_vm._e()]),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"mr-2",attrs:{"color":"primary"},model:{value:(_vm.quarter),callback:function ($$v) {_vm.quarter=$$v},expression:"quarter"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v(" Q1 ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" Q2 ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" Q3 ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" Q4 ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" FY"+_vm._s(_vm.selectedFiscalYear && _vm.selectedFiscalYear.fiscalYear ? _vm.selectedFiscalYear.fiscalYear.toString().substr(-2) : "")+" ")])],1),_c('v-btn-toggle',{staticClass:"mr-2",attrs:{"color":"primary"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v(" USA ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" CAN ")])],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"eager":""},scopedSlots:_vm._u([(_vm.showPromotionTypeFilter || _vm.showBusinessUnitFilter)?{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","fab":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Filters")))])])]}}:null],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$i18n.translate("Filters")))]),(_vm.showPromotionTypeFilter)?_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('PromotionTypesField',{attrs:{"dense":"","label":_vm.$i18n.translate('Filter') + ' by ' + _vm.$i18n.translate('Promotion Types'),"excludePromotionTypeNames":['Dealer Rebate']},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByPromotionTypes),callback:function ($$v) {_vm.filterByPromotionTypes=$$v},expression:"filterByPromotionTypes"}})],1)],1):_vm._e(),(_vm.showBusinessUnitFilter)?_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('v-select',{attrs:{"dense":"","label":_vm.$i18n.translate('Filter') + ' by ' + _vm.$i18n.translate('Business Unit'),"items":_vm.businessUnitItems,"clearable":""},on:{"click:clear":_vm.onSubmit,"change":_vm.onSubmit},model:{value:(_vm.filterByPromotionBusinessUnit),callback:function ($$v) {_vm.filterByPromotionBusinessUnit=$$v},expression:"filterByPromotionBusinessUnit"}})],1)],1):_vm._e()],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","fab":"","disabled":_vm.loading || _vm.isGeneratingReport},on:{"click":function($event){return _vm.generateReport()}}},'v-btn',attrs,false),on),[(!_vm.isGeneratingReport)?_c('v-icon',[_vm._v("mdi-exit-to-app")]):_vm._e(),(_vm.isGeneratingReport)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("Export Report")])])],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers.promotion,"items":_vm.items.promotion,"items-per-page":5,"loading":_vm.loading,"options":_vm.claimTopPromotionOptions},on:{"update:options":function($event){_vm.claimTopPromotionOptions=$event}},scopedSlots:_vm._u([{key:"item.totalPayout",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(!item.totalPayout ? 0 : item.totalPayout))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No promotions for the selected period and country ")]},proxy:true},{key:"item.promotionKey",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'allClaimTypesReporting',
                params: _vm.getParamsForPromotions(item.promotionId)
              }}},[_vm._v(" "+_vm._s(item.promotionKey)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers.organization,"items":_vm.items.organization,"items-per-page":5,"loading":_vm.loading,"options":_vm.claimTopOrganizationOptions},on:{"update:options":function($event){_vm.claimTopOrganizationOptions=$event}},scopedSlots:_vm._u([{key:"item.totalPayout",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(!item.totalPayout ? 0 : item.totalPayout))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No resellers for the selected period and country ")]},proxy:true},{key:"item.organizationKey",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'allClaimTypesReporting',
                params: _vm.getParamsForOrganizations(item.organizationId)
              }}},[_vm._v(" "+_vm._s(item.organizationKey)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers.participant,"items":_vm.items.participant,"items-per-page":5,"loading":_vm.loading,"options":_vm.claimTopParticipantOptions},on:{"update:options":function($event){_vm.claimTopParticipantOptions=$event}},scopedSlots:_vm._u([{key:"item.totalPayout",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(!item.totalPayout ? 0 : item.totalPayout))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No participants for the selected period and country ")]},proxy:true},{key:"item.participantName",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'allClaimTypesReporting',
                params: _vm.getParamsForParticipants(item.participantId)
              }}},[_vm._v(" "+_vm._s(item.participantName)+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }