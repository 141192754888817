<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-toolbar flat>
      <v-toolbar-title class="headline"
        >Submitted Claims
        <span> (Last {{ selectedDays }} Days)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" small @click="getData(7)" :loading="loading" :disabled="selectedDays == 7">
        Last 7 days
      </v-btn>
      <v-btn class="mr-2" small @click="getData(30)" :loading="loading" :disabled="selectedDays == 30">
        Last 30 days
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <apexchart type="line" height="400" :options="options" :series="series"></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "ClaimsSubmittedForWeekBarChart",
  components: {},
  data: () => ({
    loading: false,
    selectedDays: 30,

    options: {
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      xaxis: {
        type: "datetime"
      }
    },
    series: []
  }),
  methods: {
    getData(days) {
      this.loading = true;

      if (!days) {
        days = 30;
      }
      this.selectedDays = days;

      let submittedAfter = moment()
        .subtract(days, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      this.$api
        .post("/api/claims/search/byClaimCountsBySubmittedDate", {
          submitted: true,
          submittedAfter: submittedAfter
        })
        .then(({ data }) => {
          let s = this.processChartData(data);
          let { minY, maxY } = this.calculateYAxisBounds(s);

          this.options.yaxis = this.generateYAxisOptions(minY, maxY);
          this.series = [{ data: s }];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    processChartData(data) {
      return data.map(d => ({ x: d.date, y: d.count })).sort((a, b) => new Date(a.x) - new Date(b.x));
    },

    calculateYAxisBounds(data) {
      let yValues = data.map(item => item.y);
      let minY = Math.min(...yValues);
      let maxY = Math.max(...yValues);

      let rangePadding = maxY === minY ? 1 : (maxY - minY) * 0.1;
      return {
        minY: isFinite(minY) ? Math.max(0, Math.floor(minY - rangePadding)) : 0,
        maxY: isFinite(maxY) ? Math.ceil(maxY + rangePadding) : 1
      };
    },

    generateYAxisOptions(minY, maxY) {
      let tickAmount = maxY === minY ? 2 : Math.ceil((maxY - minY) / Math.ceil((maxY - minY) / 5));
      return {
        min: minY,
        max: maxY,
        tickAmount,
        labels: {
          formatter: value => value.toFixed(0)
        }
      };
    }
  },
  mounted() {
    this.options.colors = this.$chart.getColors();

    this.getData();
  }
};
</script>
