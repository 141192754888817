<template>
  <v-card v-if="selectedParticipant.reportToParticipant" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">{{ selectedParticipant.reportToParticipant.participantType.name }}</v-card-title>
    <v-card-subtitle>{{
      selectedParticipant.reportToParticipant.organization
        ? selectedParticipant.reportToParticipant.organization.name
        : ""
    }}</v-card-subtitle>
    <v-card-text class="display-1 text-center">
      {{ selectedParticipant.reportToParticipant.fullName }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ReportToWidget",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
