<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">New Registrations</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      New registrations may need your approval!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center">
      {{ count }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :to="{ name: 'cprRegistrationManagement' }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountManagerRegistrationWidget",
  data() {
    return {
      count: 0,
      loading: true
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$api
        .post("/api/participants/search/byManaged?status=PRE&size=1", {
          enrolledAfter: this.selectedFiscalYear.fiscalYearStart,
          enrolledBefore: this.selectedFiscalYear.fiscalYearEnd
        })
        .then(({ data }) => {
          this.count = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  }
};
</script>
