<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">{{ total }} Open Claims</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      Open Claims in the program!</v-card-subtitle
    >
    <v-card-text v-if="!loading">
      <v-data-table :items="items" :headers="headers" :server-items-length="6" :loading="loading" hide-default-footer>
        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDateFromNow }}
        </template>
        <template v-slot:item.claimStage.name="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.claimStage | formatStage(item) }}
              </span>
            </template>
            <span>{{ item.claimStage.description }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantClaimsWidget",
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
      headers: [
        {
          value: "createdDate",
          text: "Date Submitted",
          sortable: true
        },
        {
          value: "claimKey",
          text: "Claim Number",
          sortable: true
        },
        {
          value: "promotion.promotionKey",
          text: "Promotion Number",
          sortable: true
        },
        {
          value: "promotion.name",
          text: "Promotion",
          sortable: true
        },
        {
          value: "claimStage.name",
          text: "Status",
          sortable: true
        }
      ]
    };
  },
  mounted() {
    // no need for this because it is driven by the fiscal year 'watcher'
    // this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      let filters = {};
      filters.participant = { id: this.selectedParticipant.id };
      filters.closed = false;
      filters.submittedAfter = this.selectedFiscalYear.fiscalYearStart;
      filters.submittedBefore = this.selectedFiscalYear.fiscalYearEnd;
      this.$api
        .post("/api/claims/search", filters)
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
