<template>
  <v-container>
    <EveDashboard v-if="$sonyProgram.isEVE()" />
    <EueDashboard v-else-if="$sonyProgram.isEUR()" />
    <CprDashboard v-else />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CprDashboard from "./cpr/Dashboard";
import EveDashboard from "./eve/Dashboard";
import EueDashboard from "./eur/Dashboard";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard"
  },
  components: { CprDashboard, EueDashboard, EveDashboard },

  data: () => ({}),

  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
